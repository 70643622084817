module.exports = [
    {
       "id": "video-1",
       "title": "Learning for the Full Cycle Engineer",
       "videoid": 'NRsjDblJxZY',
       "frame": ''
    },
    {
        "id": "video-2",
        "title": "Real-world Projects for Real-world Experience",
        "videoid": 'drl_O0XS2xo',
        "frame": ''
     },
     {
        "id": "video-3",
        "title": "The Classroom Environment and Learning Experience",
        "videoid": 'Zl9AS0W5TXE',
        "frame": ''
     },
 ]